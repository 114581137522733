@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Open+Sans&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}
